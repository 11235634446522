export const ROOM_NUMBER = ["전체", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const TEAM_NUMBER = [
  "전체",
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
];
export const LOCAL = [
  "전체",
  "강원경기동부",
  "경남",
  "광주전라",
  "대구경북",
  "대전충청",
  "부산울산",
  "서울제주",
  "인천경기서부",
  "해외",
  "청년",
  "전국",
];
export const LOCAL3 = [
  "전체",
  "강원경기동부",
  "경남",
  "광주전라",
  "대구경북",
  "대전충청",
  "부산울산",
  "서울제주",
  "인천경기서부",
  "해외",
  "청년",
  "전국",
];
export const LOCAL4 = [
  "전체",
  "강원경기동부",
  "경남",
  "광주전라",
  "대구경북",
  "대전충청",
  "부산울산",
  "서울제주",
  "인천경기서부",
  "해외",
  "청년",
  "전국",
];
export const TEAM_NUMBER2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
export const ROOM_NUMBER2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
export const LOCAL2 = [
  "강원경기동부",
  "경남",
  "광주전라",
  "대구경북",
  "대전충청",
  "부산울산",
  "서울제주",
  "인천경기서부",
  "해외",
  "청년",
]; 

export const BSECTION = [
  "전체",
  "입학식",
  "실천적불교사상",
  "인간붓다",
  "수행맛보기/불교와사회",
];
export const KSECTION = [
  "전체",
  "입학식",
  "대승불교/금강경",
  "반야심경/법화경/화엄경",
  "육조단경/한국불교/세계불교",
];
// 지역명 - 불교대학용
export const LOCAL_NAMES_B = [
  "전체",
  "서울제주",
  "강원경기동부",
  "인천경기서부",
  "대전충청",
  "광주전라",
  "대구경북",
  "부산울산",
  "경남",
  "해외",
  "청년",
  "전국",
];
export const LOCAL_NAMES_B2 = [
  "서울제주",
  "강원경기동부",
  "인천경기서부",
  "대전충청",
  "광주전라",
  "대구경북",
  "부산울산",
  "경남",
  "해외",
  "청년",
];
export const LESSON_TYPES = {
  online: "온라인 수업",
  talk: "즉문즉설",
  offline: "실천활동",
};

export const BLESSON_NUMBER = {
  online: 19,
  talk: 3,
  offline: 4,
};

export const KLESSON_NUMBER = {
  online: 19,
  talk: 3,
  offline: 4,
};

//불대 온라인수업 수업명 리스트 24년 이후
export const BLESSON_NAMES = [
  "입학식",
  "실천적불교사상 1회",
  "실천적불교사상 2회", 
  "불교와 환경",
  "나누기 수련", 
  "실천적불교사상 3회",
  "실천적불교사상 4회", 
  "실천적불교사상 5회",
  "실천적불교사상 6회",
  "인간 붓다 1회", 
  "인간 붓다 2회",
  "불교와 복지", 
  "인간 붓다 3회", 
  "인간 붓다 4회",  
  "천일결사 맛보기",
  "불교와 평화",
  "인간 붓다 5회",
  "인간 붓다 6회",
  "인간 붓다 7회", 
];

//경대 온라인수업 수업명 리스트 24년 이후
export const KLESSON_NAMES = [
  "입학식",
  "대승불교 1회",
  "금강경 1회",
  "금강경 2회",
  "금강경 3회",
  "금강경 4회",
  "금강경 5회",
  "금강경 6회",
  "한국불교 1회",
  "반야심경 1회",
  "반야심경 2회",
  "반야심경 3회",
  "반야심경 4회",
  "법화경 1회",
  "화엄경 1회",
  "육조단경 1회",
  "육조단경 2회",
  "육조단경 3회",  
  "세계불교 1회", 
];

//불대 온라인수업 수업명 리스트 23년 이전
export const BLESSON_NAMES2 = [
  "입학식",
  "실천적불교사상 1주",
  "실천적불교사상 2주",
  "불교와 환경",
  "나누기 수련",
  "실천적불교사상 3주",
  "실천적불교사상 4주",
  "실천적불교사상 5주",
  "실천적불교사상 6주",
  "인간 붓다 1주",
  "인간 붓다 2주",
  "불교와 복지",
  "천일결사 맛보기",
  "인간 붓다 3주",
  "인간 붓다 4주",
  "불교와 평화",
  "인간 붓다 5주",
  "인간 붓다 6주",
  "인간 붓다 7주",
  "즉문즉설1",
  "즉문즉설2",
  "즉문즉설3",
  "1차 실천활동",
  "2차 실천활동",
  "3차 실천활동",
  "4차 실천활동",
];

//경대 온라인수업 수업명 리스트 23년 이전
export const KLESSON_NAMES2 = [
  "입학식",
  "대승불교 1, 2강",
  "금강경 1, 2강",
  "금강경 3, 4강",
  "금강경 5, 6강",
  "금강경 7, 8강",
  "금강경 9, 10강",
  "금강경 11, 12강",
  "반야심경 1, 2강",
  "반야심경 3, 4강",
  "반야심경 5, 6강",
  "반야심경 7, 8강",
  "법화경 1, 2강",
  "화엄경 1, 2강",
  "육조단경 1, 2강",
  "육조단경 3, 4강",
  "육조단경 5, 6강",
  "한국불교 1, 2강",
  "세계불교 1, 2강",
  "즉문즉설1",
  "즉문즉설2",
  "즉문즉설3",
  "1차 실천활동",
  "2차 실천활동",
  "3차 실천활동",
  "4차 실천활동",
];

//불대 수업보고서/수기출석부 수업리스트
export const BLESSON_NAMES3 = [
  "입학식",
  "실천적불교사상 1회",
  "실천적불교사상 2회", 
  "불교와 환경",
  "나누기 수련",
  "실천적불교사상 3회",
  "실천적불교사상 4회",
  "실천적불교사상 5회",
  "실천적불교사상 6회",
  "인간 붓다 1회",
  "인간 붓다 2회",
  "불교와 복지",
  "인간 붓다 3회", 
  "인간 붓다 4회",  
  "천일결사 맛보기",
  "불교와 평화",
  "인간 붓다 5회",
  "인간 붓다 6회",
  "인간 붓다 7회", 
  "즉문즉설1",
  "즉문즉설2",
  "즉문즉설3",
  "실천활동1(환경)",
  "실천활동2(으뜸절)",
  "실천활동3(복지)",
  "실천활동4(평화)",
];

//경대 수업보고서/수기출석부 수업리스트
export const KLESSON_NAMES3 = [
  "입학식",
  "대승불교 1회",
  "금강경 1회",
  "금강경 2회",
  "금강경 3회",
  "금강경 4회",
  "금강경 5회",
  "금강경 6회", 
  "반야심경 1회",
  "반야심경 2회",
  "반야심경 3회",
  "반야심경 4회",
  "법화경 1회",
  "화엄경 1회",
  "육조단경 1회",
  "육조단경 2회",
  "육조단경 3회",  
  "한국불교 1회",
  "세계불교 1회", 
  "즉문즉설1",
  "즉문즉설2",
  "즉문즉설3",
  "실천활동1(으뜸절)",
  "실천활동2(복지)",
  "실천활동3(환경)",
  "실천활동4(평화)",
];

export const ROLE_TYPES = {
  "class leader": "반담당",
  "team leader": "진행자",
  "team assist": "돕는이",
  branch: "지부담당",
  mentor: "법사",
  observer: "참관자",
  manager: "관리자",
};
export const SEMESTER = [
  "불교대학 2024 9월",
  "경전대학 2024 9월",
  "불교대학 2024 3월",
  "경전대학 2024 3월",
  "불교대학 2023 9월",
  "경전대학 2023 9월",
  "불교대학 2023 3월",
  "경전대학 2023 3월",
  "불교대학 2022 9월",
  "경전대학 2022 9월",
];

export const SEMESTER2 = [
  "불교대학 2024 9월",
  "경전대학 2024 9월", 
];

export const PRESENT = {
  year : 2024,
  semester: "9월",
}

export const TOTALSEMESTER = ["2024 9월", "2024 3월", "2023 9월", "2023 3월", "2022 9월"];
