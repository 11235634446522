<!-- 내정보 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h3 class="text-white d-inline-block mb-0">내 정보</h3>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0 title">내 정보</h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          <ul class="list-group list-group-flush list my--3">
            <li class="list-group-item px-0 mb-2">
              <div class="row">
                <span
                  style="
                    display: inline-block;
                    height: 30px;
                    width: 100px;
                    margin-left: 15px;
                  "
                  >이 름:</span
                >
                <span class="col">{{ data.name }}</span>
              </div>
              <div class="row">
                <span
                  style="
                    display: inline-block;
                    height: 30px;
                    width: 100px;
                    margin-left: 15px;
                  "
                  >이메일:</span
                >
                <span class="col">{{ data.email }}</span>
              </div>
              <div class="row">
                <span
                  style="
                    display: inline-block;
                    height: 30px;
                    width: 100px;
                    margin-left: 15px;
                  "
                  >년도:</span
                >
                <span class="col">{{ data.year }}</span>
              </div>
              <div class="row">
                <span
                  style="
                    display: inline-block;
                    height: 30px;
                    width: 100px;
                    margin-left: 15px;
                  "
                  >학기:</span
                >
                <span class="col">{{ data.semester }}</span>
              </div>
              <div class="row">
                <span
                  style="
                    display: inline-block;
                    height: 30px;
                    width: 100px;
                    margin-left: 15px;
                  "
                  >학기코드:</span
                >
                <span class="col">{{ data.semesterCode }}</span>
              </div>
              <div class="row">
                <span
                  style="
                    display: inline-block;
                    height: 30px;
                    width: 100px;
                    margin-left: 15px;
                  "
                  >학년:</span
                >
                <span class="col">{{ data.grade }}</span>
              </div>
              <div class="row">
                <span
                  style="
                    display: inline-block;
                    height: 30px;
                    width: 100px;
                    margin-left: 15px;
                  "
                  >기수:</span
                >
                <span class="col">{{ data.periodNo }}</span>
              </div>
              <div class="row">
                <span
                  style="
                    display: inline-block;
                    height: 30px;
                    width: 100px;
                    margin-left: 15px;
                  "
                  >지 역:</span
                >
                <span class="col">{{ data.local }}</span>
              </div>
              <div class="row">
                <span
                  style="
                    display: inline-block;
                    height: 30px;
                    width: 100px;
                    margin-left: 15px;
                  "
                  >역 할:</span
                >
                <span class="col">{{ data.role }}</span>
              </div>
              <div class="row">
                <span
                  style="
                    display: inline-block;
                    height: 30px;
                    width: 100px;
                    margin-left: 15px;
                  "
                  >교실코드:</span
                >
                <span class="col">{{ data.roomCode }}</span>
              </div>
              <div class="row">
                <span
                  style="
                    display: inline-block;
                    height: 30px;
                    width: 100px;
                    margin-left: 15px;
                  "
                  >반:</span
                >
                <span class="col">{{ data.room }}</span>
              </div>

              <div class="row">
                <span
                  style="
                    display: inline-block;
                    height: 30px;
                    width: 100px;
                    margin-left: 15px;
                  "
                  >조:</span
                >
                <span class="col">{{ data.team }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "@/api";
import { ref } from "vue";

export default {
  // 함수 추가
  setup() {
    // api 호출
    const data = ref({});
    const getDataFromApi = () => {
      api.get(`service/user/user`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const result = response.data;
        let role = "";
        if (result.data.role.includes("manager")) {
          role = "관리자";
        }
        if (result.data.role[0].includes("branch")) {
          if (role == "") {
            role = "지부담당";
          } else {
            role = role + "/지부담당";
          }
        }
        if (result.data.role.includes("class leader")) {
          if (role == "") {
            role = "반담당";
          } else {
            role = role + "/반담당";
          }
        }
        if (result.data.role.includes("team leader")) {
          if (result.data.role == "") {
            role = "진행자";
          } else {
            role = result.data.role + "/진행자";
          }
        }
        if (result.data.role.includes("team assist")) {
          if (role == "") {
            role = "돕는이";
          } else {
            role = role + "/돕는이";
          }
        }

        if (result.data.role[0].includes("mentor")) {
          if (role == "") {
            role = "법사";
          } else {
            role = role + "/법사";
          }
        }
        if (result.data.role[0].includes("observer")) {
          if (role == "") {
            role = "참관자";
          } else {
            role = role + "/참관자";
          }
        }
        if (result.data.role[0].includes("master")) {
          result.data.role = "관리자**";
        }
        result.data.role = role;
        result.data.roomCode = result.data.local + result.data.room + "반";
        data.value = result.data;
      });
    };
    getDataFromApi();
    return {
      data,
    };
  },
  mounted() {
    const modal = document.querySelector(".header");
    if (this.$store.state.darkMode) {
      modal.classList.remove("bg-success");
    }
  },
};
</script>
<style></style>
